import React from 'react';
import { PageProps } from 'gatsby';
import { CaaPage } from 'features/solutions';
import { Locale } from 'features/localization';

function Page({ pageContext: { locale } }: PageProps<unknown, { locale: Locale }>) {
  return <CaaPage locale={locale} />;
}

export default Page;
